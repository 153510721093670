<script>
import DetailBoxBike from '@/components/DetailBoxBike'
import AppFooter from '@/components/AppFooter'

export default {
  name: 'pozicovna-elektrobicyklov',
  components: {
    DetailBoxBike,
    AppFooter
  },
  data () {
      return {
        defaultPricelist: [['Elektrobicykel', 29, 25, 0], ['Celoodpružený elektrobicykel', 35, 30, 0], ['Elektro-kolobežka', 50, 30, 10]]
    }
  },
  methods: {
    scrollTo(ref) {
      const element = this.$refs[ref].$el
      window.scrollTo(0, element.offsetTop - 120);
    },
    openModal(n) {
      this.$refs[n].$refs["modal"].open()
    }
  }
};
</script>

<template>
  <div class="pozicovna-view">
    <article>
      <div class="content-wrapper">
        <h1>Požičovňa elektrobicyklov</h1>
        
        <p>Naša spoločnosť ponúka služby požičovne elektrobicyklov v 3 prevádzkach. Vyrazte na Liptov a zažite dobrodružstvá na lesných cestách alebo obľúbených cyklochodníkoch v množstve dolín. Elektrobicykle mamé pripravené pre celú Vašu rodinu, ako doplnok ponúkame aj cyklovozík alebo detskú sedačku. Spokojnosť našich zákazníkov je u nás na prvom mieste, ponúkame preto výber kvalitných značiek v rôznych modeloch a veľkostiach:</p>
        <div class="logo-boxes">
          <div class="logo-box">
            <span>bicykle</span>
            <img src="/images/logo/focus.jpg" alt="Focus">
            <img src="/images/logo/haibike.png" alt="Haibike">
            <img src="/images/logo/ktm.png" alt="KTM">
            <img src="/images/logo/cube.png" alt="Cube">
          </div>
          <div class="logo-box">
            <span>kolobežky</span>
            <img src="/images/logo/crussis.png" alt="Crussis">
            <img src="/images/logo/hugo-bike.png" alt="Hugo Bike">
          </div>
        </div>

        <h2>Vyberte si zo širokej ponuky horských (MTB) a crossových elektrobicyklov</h2>
      </div>

      <div class="boxes">
        <detail-box-bike title="Focus Jarifa 66" v-bind:pricelist="defaultPricelist" 
                    class="box" image="ebike/focus-jarifa-66.jpg" bg="#c39e6b" ref="1">
          <p>Správny typ elektrickej asistencie pre každého - kompaktné a integrované pre maximálny pôžitok z jazdy.</p>
          <template slot="popis">
            <p>V cene požičania je helma, zámok, servisná brašňa - taška a voliteľná detská sedačka. Ostatné podľa platného cenníka.</p>
          </template>
          <template slot="image">
            <Imgix src="ebike/focus-jarifa-66.jpg" alt="Focus Jarifa 66" />
          </template>
        </detail-box-bike>

        <detail-box-bike title="Haibike SDURO ALL MTN 7.0" v-bind:pricelist="defaultPricelist" image="ebike/hibike-sduro-allmtn.jpg" class="box" bg="#afbd13" ref="2">
          <p>Prirodzená rovnováha s prírodou. Haibike SDURO Vás dostane aj do tažkých terénov. Nemecká kvalita na ktorú sa môžete spoľahnúť.</p>
          <template slot="popis">
            <p>V cene požičania je helma, zámok, servisná brašňa - taška a voliteľná detská sedačka. Ostatné podľa platného cenníka.</p>
          </template>
          <template slot="image">
            <Imgix src="ebike/hibike-sduro-allmtn.jpg" alt="Haibike SDURO ALL MTN 7.0" />
          </template>
        </detail-box-bike>

        <detail-box-bike title="KTM Macina Kapoho" v-bind:pricelist="defaultPricelist" class="box" image="ebike/ktm-macina-kapoho.jpg" bg="#5ac1a4" ref="3">
          <p>Tento karbónový celoodpružený elektrobicykel Vás dovezie do ťažkého terénu a na lesné cesty. Rakúska kvalita značky KTM.</p>
          <template slot="popis">
            <p>V cene požičania je helma, zámok, servisná brašňa - taška a voliteľná detská sedačka. Ostatné podľa platného cenníka.</p>
          </template>
          <template slot="image">
            <Imgix src="ebike/ktm-macina-kapoho.jpg" alt="KTM Macina Kapoho" />
          </template>
        </detail-box-bike>

        <detail-box-bike title="KTM Macina Ride" v-bind:pricelist="defaultPricelist" class="box" image="ebike/ktm-macina-ride.jpg" bg="#ff7d3d" ref="4">
          <p>Horský elektrobicykel s 29" kolesami od značky KTM. Určený predovšetkým do ľahšieho terénu a na poľné cesty.</p>
          <template slot="popis">
            <p>V cene požičania je helma, zámok, servisná brašňa - taška a voliteľná detská sedačka. Ostatné podľa platného cenníka.</p>
          </template>
          <template slot="image">
            <Imgix src="ebike/ktm-macina-ride.jpg" alt="KTM Macina Ride" />
          </template>
        </detail-box-bike>

        <detail-box-bike title="CUBE Acid" v-bind:pricelist="defaultPricelist" class="box" image="ebike/cube-acid.jpg" bg="#0d88da" ref="5">
          <p>Detský horský elektrobicykel pre Vašich mladých spoluobjaviteľov.</p>
          <template slot="image">
            <Imgix src="ebike/cube-acid.jpg" alt="CUBE Acid" />
          </template>
          <template slot="popis">
            <p>V cene požičania je helma, zámok, servisná brašňa - taška a voliteľná detská sedačka. Ostatné podľa platného cenníka.</p>
          </template>
        </detail-box-bike>

        <detail-box-bike title="Cyklodoplnky a ďalšia výbava" v-bind:pricelist="[]" class="box" image="bike/accessories.jpg" bg="#bbce44" ref="7">
          <p>Požičovňa a predaj cyklodoplnkov a výbavy</p>
          <template slot="image">
            <Imgix src="bike/accessories.jpg" alt="Cyklodoplnky a ďalšia výbava" />
          </template>
          <template slot="popis">
            <p><b>Predaj výbavy</b><br>
                pumpa, lepenie, multikľúč, prilba, rukavice, dres + cyklokraťasy, pedále, fľaša na pitie, brašnička, montpáky, okuliare<br>
                <b>Požičanie výbavy</b><br>
                cyklo vozík, sedačky pre deti (len s požičaním bicyklov od nás)</p>
          </template>
        </detail-box-bike>
      </div>

      <div class="full-box bg-ebikepoint">
        <div class="full-box-content">
          <h3 style="color: #52b04c">Náš partner eBikePOINT v prevádzke Tatra Line Resort Demänová</h3>
          <p><font-awesome-icon icon="check-circle" /> predaj jazdených a nových elektrobicyklov</p>
          <p><font-awesome-icon icon="check-circle" /> profesionálny servis elektrobicykel</p>
        </div>
      </div>

      <div class="full-box content-right bg-cyklotrasy">
        <div class="full-box-content">
          <h3 style="color: #96d400">Cyklotrasy a tipy na výlety na Liptove</h3>
          <p><font-awesome-icon icon="check" style="margin-right: .5em" /> <a href="https://www.visitliptov.sk/trasa/vychodna-horna-nadrz-cierny-vah/" target="_blank" title="Horná nádrž Čierny Váh (1 160 m n.m.) z Východnej">Horná nádrž Čierny Váh (1 160 m n.m.) z Východnej</a>, viac na <a href="https://visitliptov.sk" target="_blank" title="">visitliptov.sk</a></p>
          <p><font-awesome-icon icon="check" style="margin-right: .5em" /> <a href="https://www.mtbiker.sk/vyjazdy/638807/bicykel-svidovo.html" target="_blank" title="Svidovo">Svidovo</a> a ďalšie tipy na <a href="https://mtbiker.sk" target="_blank" title="">mtbiker.sk</a></p>
          <p><font-awesome-icon icon="check" style="margin-right: .5em" /><a href="https://cyklotrasy.biker.sk/ilanovska-dolina/" target="_blank" title="Iľanovská dolina">Iľanovská dolina</a> a iné na <a href="https://cyklotrasy.biker.sk/kraj/zilinsky-kraj/" target="_blank" title="">cyklotrasy.biker.sk</a></p>
        </div>
      </div>

      <div class="full-box bg-prevadzka">
        <div class="full-box-content full-box-content-dark">
          <h3>Kde nás nájdete</h3>
          <p><font-awesome-icon icon="map-marker-alt" /> v Liptovskom Jáne - v Hoteli Ďumbier</p>
          <p><font-awesome-icon icon="map-marker-alt" /> v Demänovej - Tatra Line Resort Demänová, vedľa čerpacej stanice OMV</p>
          <p><font-awesome-icon icon="map-marker-alt" /> v Liptovskom Trnovci - na parkovisku pri Liptovskej Mare oproti Penziónu Ravence</p>
          <br>
          <p><font-awesome-icon icon="phone-alt" /> +421 905 357 133</p>
          <br>
          <p><router-link to="/kontakt" class="button">Viac</router-link></p>
        </div>
      </div>
    </article>
    <app-footer>
      <b>Požičovňa lyžiarskej a snowboardovej výstroje:</b>
        <ul>
          <li><a @click="openModal('1')">Horské elektrobicykle</a></li>
          <li><a @click="openModal('2')">Trekingové elektrobicykle</a></li>
          <li><a @click="openModal('7')">Požičovňa a predaj cyklodoplnkov a výbavy</a></li>
        </ul>
    </app-footer>
  </div>
</template>

<style lang="scss" scoped>
  $title-color: #25b430;

  .content-wrapper {
    width: 90%;
    margin: 1rem auto;

    & > p {
      font-size: 18px;
      margin: 1rem 0;
    }
  }

  h1 {
    display: none;
  }

  h2 {
    color: $title-color;
    font-size: 30px;
    margin: 2.5rem 0 2rem;
    text-transform: uppercase;
  }

  .boxes {
    .box {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);
        transform: rotateZ(.5deg);
        z-index: -10;
        filter: blur(3px);
        border-radius: 5px;
      }

      ::v-deep h3 {
        font-family: "Montserrat";
        font-size: 23px;
        color: #fff;
        margin: -3.5rem 0 0;
        padding: .5rem 1rem;
        text-transform: uppercase;
        position: relative;
        background: rgba(0,0,0,.5);
      }
      
      ::v-deep .sweet-content {
        @media (min-width: 1024px) {
          aside {
            float: right;

            img {
              width: 450px;
              margin-left: 20px;
            }
          }
        }

        p {
          color: #444;
          font-size: 1rem;
          padding: 1rem 0;
        }

        h4 {
          font-size: 18px;
          font-weight: bold;
          clear: right;
        }

        table {
          margin: 1rem 0;
          padding: 0;
          width: 100%;
          font-size: 1rem;

          tr {
            border-bottom: 1px solid #eee;

            td {
              vertical-align: middle;
              padding: .75rem;
            }

            th {
              vertical-align: middle;
              padding: .35rem .75rem;
              background: #d9f3ff;
            }
          }
        }
      }

      ::v-deep .sweet-buttons {
        border: none;
        text-align: center;
        padding-bottom: 2rem;
      }

      ::v-deep .button-action {
        font-weight: normal;
        font-size: 1.1rem;
        border-radius: 2rem;
        padding: 0.5rem 1.5rem;
      }

      p {
        padding: 1rem;
        margin: 0;
        color: #fff;
      }
    }
  }

  .full-box {
    background-size: contain;
    padding: 2rem 3%;

    p {
      line-height: 2;
    }

    h3 {
      font-weight: 200;
      font-size: 30px;
      margin: 1rem 0;
    }

    &.full-box-night-service {
      p {
        color: #fff;
        width: 50%;
      }
    }

    a {
      color: $title-color;
    }
  }

  .button {
    font-weight: normal;
    font-size: 1rem;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
    background: #eee;
    color: #555;
    text-transform: uppercase;
  }
</style>
